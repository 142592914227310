body {
    /* background-color: #3b5998; */
  }
  
  .fb-chat {
    /* background-color: #E6E7EC; */
    width: 70px;
    line-height: 35px;
    display: block;
    border-radius: 30% / 50%;
    transform: translate(-50%, -50%);
    border: none;
    box-shadow: none;
  }
  .fb-chat--bubbles {
    text-align: center;
  }
  .fb-chat--bubbles span {
    display: inline-block;
    background-color: #B6B5BA;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin-right: 5px;
    animation: bob 2s infinite;
    /* margin-bottom: 2px; */
  }
  .fb-chat--bubbles span:nth-child(1) {
    animation-delay: -1s;
  }
  .fb-chat--bubbles span:nth-child(2) {
    animation-delay: -0.85s;
  }
  .fb-chat--bubbles span:nth-child(3) {
    animation-delay: -0.7s;
    margin-right: 0;
  }
  
  @keyframes bob {
    10% {
      transform: translateY(-10px);
      background-color: #9E9DA2;
    }
    50% {
      transform: translateY(0);
      background-color: #B6B5BA;
    }
  }
  